import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      backTop: null
    }
  },
  mounted() {
    this.handleBackTopVisible('hidden')
  },
  activated() {
    this.handleBackTopVisible('hidden')
  },
  deactivated() {
    this.handleBackTopVisible('visible')
  },
  unmounted() {
    this.handleBackTopVisible('visible')
  },
  methods: {
    // 产品要求隐藏backTop按钮
    handleBackTopVisible(visible) {
      if (!window) return
      if (!this.backTop) {
        this.backTop = document?.querySelector('.mshe-z-returnTop') || null
      }
      const cb = () => {
        if (this.backTop && this.backTop.style) this.backTop.style.visibility = visible
      }
      if (window.requestIdleCallback) {
        requestIdleCallback(() => cb())
      } else {
        setTimeout(() => cb(), 100)
      }
    }
  }
})
