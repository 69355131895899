import { componentStateFactory } from '../product_list_v2/state'
const state = {
  contextForSSR: null,
  hooksGoods: null, // 钩子商品信息
  abtInfo: {}, // abt
  pageType: 'A', // A: 大卡片 + 推荐列表 B: 推荐列表
  language: {},
  locals: {}, 
  itemConfig: {
    ...(componentStateFactory().ProductList.config),
  }, // 商品项配置
  sheinClubInfo: {}, // shein club
  cccConfig: {}, // ccc配置
  catInfo: {},
  atomicFields: {},
  suggestedSalePriceType: '', // wiki: pageId=1347754607 新增用
  APOLLO_CONFIG: {}, // apollo配置
  fetchSearchTermsParams: {
    scene: 'home',
    word_type: 1,
    list_scene: 'outside_station_search',
    goods_id: '',
    cat_id: '',
  }, // 给搜索框传递的参数
  category: [], // 分类
  firstLoadGoods: [], // 首次加载的商品
  httpConfig: {
    method: 'POST',
    featureKey: 'search_association_words_cycle',
  }
}

export default state
