<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AdvertisingBanner',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  render({ $props: props }) {
    const { src } = props
    return (src && <img class="advertising-banner" src={src}></img>)
  },
})
</script>

<style lang="less">
.advertising-banner {
  width: 100%;
  // 宽度的48/125
  height: calc((48 / 125) * 10rem);
}
</style>
