const actions = {
  initState ({ getters, commit }, payload) {
    commit('setApolloConfig', payload.APOLLO_CONFIG)
    commit('setSuggestedSalePriceType', payload.suggestedSalePriceType)
    commit('setAbtInfo', payload.listAbtResult)
    commit('setHooksGoods', payload.hooksGoods)
    commit('setLanguage', payload.language)
    commit('setPageType', payload.pageType)
    commit('initSheinClubInfo', payload.sheinClubInfo)
    commit('initCatInfo', payload.cat_info)
    commit('initCccConfig', payload.cccConfig)
    commit('initAtomicFields', payload?.atomicParams?.fields || {})
    commit('initSearchBox', payload.showSearchInput)
    commit('initItemConfig', payload)
    commit('initFetchSearchTermsParams', getters)
    commit('changeCategory', payload.category)
    commit('changeFirstLoadGoods', payload.goods)
  }
}

export default actions
